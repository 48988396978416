import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, Table } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntity, updateEntity, createEntity, reset } from './produit.reducer';
import { getEntitiesByProduit as getVersionsProduit } from '../version/version.reducer';
import { getEntitiesByProduit as getVariablesProduit,
  deleteEntityProduit as deleteVariable ,
  updateEntity as updateVariable ,
  createEntity as createVariable} from '../variable/variable.reducer';
import moment from 'moment';
import { AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import InfoUpdateModal from '../variable/info-update-modal';
import {versionEtat} from 'app/config/codifications'
import {codeTypes as codification} from 'app/config/codifications'
import ConfirmModal from '../confirm-modal';
import { getCodes } from '../code/code.reducer';

export interface IProduitUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ProduitUpdate = (props: IProduitUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [formatNom, setFormatNom] = useState('');
  const [currentEntity, setcurrentEntity] = useState({id:0,produitId:0});
  const [showDialog, setShowDialog] = useState(false);
  const [currentAction, setCurrentAction] = useState('');
  const [currentTypeEntity, setCurrentTypeEntity] = useState();
  const [currentConfirmationMessage, setCurrentConfirmationMessage] = useState(<></>);


  const {
    entity,
    versions,
    variableEntities,
    loadingVariables,
    updatingVariable,
    typesVariable,
    loadingVersion,
    loading,
    updating,
    isConseiller
  } = props;



  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
      props.getVersionsProduit(props.match.params.id);
      props.getVariablesProduit(props.match.params.id);
      if (typesVariable.length  === 0)
        props.getCodes({codeType: codification.VARIABLE_TYPE})
    }

  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      props.history.push('/catalogue/produit');
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const updatingEntity = {
        ...entity,
        ...values,
      };

      if (isNew) {
        props.createEntity(updatingEntity);
      } else {
        props.updateEntity(updatingEntity);
      }
    }
  };

  const handleDelete = (type, variableEntity) => {
    setCurrentAction('confirm')
    setcurrentEntity(variableEntity);
    setCurrentTypeEntity(type);
    setCurrentConfirmationMessage(<>Etes vous sur de vouloir supprimer cette information ?<br /><br />Cette action est irréversible.</>)
    setShowDialog(true);
  }

  const confirmCreerVariable = (values) => {
    if (currentEntity.id){
      props.updateVariable( {
          ...values,
          id:currentEntity.id,
          produitId: entity.id
        });
    }else{
      props.createVariable({
        ...values,
        produitId: entity.id
      });
    }
    setShowDialog(false);
  }

  const confirmDelete = () => {
    props.deleteVariable(currentEntity.id, currentEntity.produitId)
    setShowDialog(false);
  }

  const handleUpdate = (type, entityVariable) => {
    setCurrentAction(`update-${type}`)
    setcurrentEntity(entityVariable);
    setShowDialog(true);
  }

  const formatterNom = (value) => {

    const rules = {
			a:"àáâãäå",
			A:"ÀÁÂ",
			e:"èéêë",
			E:"ÈÉÊË",
			i:"ìíîï",
			I:"ÌÍÎÏ",
			o:"òóôõöø",
			O:"ÒÓÔÕÖØ",
			u:"ùúûü",
			U:"ÙÚÛÜ",
			y:"ÿ",
			c: "ç",
			C:"Ç",
			n:"ñ",
			N:"Ñ"
    };

    if (value) {
      value =value.replaceAll(' ','_').replaceAll('-','_').replaceAll("'",'');
      Object.keys(rules).forEach(rule =>
      {
        for(const car of rules[rule]){
          value = value.replaceAll(car, rule);
        }
      })

      return value.toUpperCase();
    }
    return "";
  }

  const changeCode = (target) =>{
    setFormatNom(formatterNom(target.value))
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="10">
          <h2 id="tarifApp.version.home.createOrEditLabel">
            <Button tag={Link} id="cancel-save" to="/catalogue/produit" replace color="info" style={{marginRight:'20px'}}>
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Retour</span>
            </Button>
            Création ou Modification d{"'"}un produit
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="10">
        <fieldset>
          <legend className="legend">Informations générales</legend>


          {loading ? (
            <p>Chargement des informations générales..</p>
          ) : (
            <AvForm model={isNew ? {} : entity} onSubmit={saveEntity}>
              <AvGroup>
                <Label id="nomLabel" for="produit-nom">
                  Nom
                </Label>
                <AvField
                    id="produit-nom"
                    type="text"
                    name="nom"
                    onChange={ (e) => changeCode(e.target) }
                    validate={{
                      required: { value: true, errorMessage: 'Le champ est obligatoire.' },
                    }}
                  />
              </AvGroup>
              <AvGroup>
                <Label id="codeLabel" for="produit-code">
                  Code
                </Label>
                <AvField
                    id="produit-code"
                    type="text"
                    readOnly={true}
                    value={formatNom}
                    name="code"
                    validate={{
                      required: { value: true, errorMessage: 'Le champ est obligatoire.' },
                    }}
                  />
              </AvGroup>
              <AvGroup>
                <Label id="descriptionLabel" for="produit-description">
                  Description
                </Label>
                <AvField id="produit-description" type="textarea" name="description" rows="5" />
              </AvGroup>
              &nbsp;
              <Button color="info" id="save-entity" type="submit" disabled={updating} className="float-right ">
                <FontAwesomeIcon icon="save" />
                &nbsp; Enregistrer
              </Button>
            </AvForm>

          )}
          </fieldset>
          {!isNew && (
            <>
            <fieldset>
              <legend className="legend">Versions</legend>
              <dl className="jh-entity-details">
              {!loadingVersion ? (
                <>
                {versions && versions.length > 0 && (
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Référence</th>
                        <th>Etat</th>
                        <th>Date de publication</th>
                        <th>Publieur</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {versions.map((version, i) => (
                        <tr key={`entity-${i}`}>
                          <td>{version.reference}</td>
                          <td>{version.etat}</td>
                          <td>{version.publicationDate ? moment(version.publicationDate).format("DD/MM/YYYY HH:MM:SS") : ""}</td>
                          <td>{version.publieur}</td>
                          <td>
                          {(isConseiller || version.etatCode !== versionEtat.BROUILLON) && (
                            <Button tag={Link} to={`/catalogue/version/${version.id}`} color="info" size="sm" style={{marginRight:'5px'}}>
                              <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">Consulter</span>
                            </Button>
                            )}

                            {!isConseiller && (
                            <>
                              {version.etatCode === versionEtat.BROUILLON && (
                                <Button tag={Link} to={`/catalogue/version/${version.id}/edit`} color="primary" size="sm" style={{marginRight:'5px'}}>
                                  <FontAwesomeIcon icon="pencil-alt" /> &nbsp;<span className="float-right d-none d-md-inline">Modifier</span>
                                </Button>
                              )}

                              {version.etatCode !== versionEtat.BROUILLON && (
                                <Button tag={Link} to={`/catalogue/version/${version.id}/delete`} color="danger" size="sm">
                                  <FontAwesomeIcon icon="trash" /> &nbsp;<span className="float-right d-none d-md-inline">Supprimer</span>
                                </Button>
                              )}
                            </>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  )}
                {!versions || (versions && versions.length === 0) && ("Aucune version")}
                </>) : ( <p>Chargement des versions du produit...</p>)}
              </dl>
            </fieldset>

            <fieldset>
              <legend  className="legend">Informations Prospect</legend>

              {loadingVariables ? (
                <p>Chargement des informations complémentaires...</p>
              ) : (
                <>
                  <div className="table-responsive">
                    {!variableEntities || (variableEntities && variableEntities.length === 0) && ("Aucune informations")}

                    {variableEntities && variableEntities.length > 0 && (
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Code</th>
                            <th>Nom</th>
                            <th>Description</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {variableEntities.map((variable, i) => (
                            <tr key={`entity-${i}`}>
                              <td>{variable.code}</td>
                              <td>{variable.nom}</td>
                              <td>{variable.description}</td>
                              <td className="text-right">
                                <div className="btn-group flex-btn-group-container">
                                  <Button onClick={() => handleUpdate("variable", variable)} color="info" size="sm" style={{marginRight:'5px'}}>
                                    <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline"></span>
                                  </Button>
                                  <Button onClick={() => handleDelete("variable", variable)} color="danger" size="sm">
                                    <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline"></span>
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      )}
                  </div>
                  <Button onClick={() => handleUpdate("variable", {})} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
                    <FontAwesomeIcon icon="plus" />
                    &nbsp; Nouvelle information
                  </Button>
                </>
              )}
            </fieldset>
          </>
          )}
        </Col>
      </Row>


      <ConfirmModal
        showModal={showDialog && currentAction === "confirm"}
        title="Confirmation de la suppression"
        message={currentConfirmationMessage}
        handleValid={() => {confirmDelete()}}
        handleClose={() => {setShowDialog(false)}}
      />

      <InfoUpdateModal
        showModal={showDialog && currentAction === "update-variable"}
        handleClose={() => {setShowDialog(false)}}
        handleValid={confirmCreerVariable}
        updating={updatingVariable}
        entity={currentEntity}
        variablesVersion={variableEntities}
      />
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  entity: storeState.produit.entity,
  versions: storeState.version.entities && storeState.produit.entity ? storeState.version.entities[storeState.produit.entity.id] : undefined,
  variableEntities: storeState.variable.entities.byProduit[storeState.produit.entity.id],
  typesVariable: storeState.code.entities && storeState.code.entities[codification.VARIABLE_TYPE] ? storeState.code.entities[codification.VARIABLE_TYPE] : [],
  loading: storeState.produit.loading,
  loadingVersion: storeState.version.loading,
  loadingVariables: storeState.variable.loading,
  updatingVariable: storeState.variable.updating,
  updating: storeState.produit.updating,
  updateSuccess: storeState.produit.updateSuccess,
  isConseiller: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.CONSEILLER])
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getVersionsProduit,
  reset,
  getVariablesProduit,
  deleteVariable,
  createVariable,
  updateVariable,
  getCodes
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProduitUpdate);
