import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, Table } from 'reactstrap';
import { IRootState } from 'app/shared/reducers';
import { getEntity } from './version.reducer';
import { createEntity as creerEvaluation } from '../evaluation/evaluation.reducer';
import { genererDevis } from '../document/document.reducer';
import { reset } from '../document/document.reducer';
import { getEntitiesByVersion as getVariablesVersion} from '../variable/variable.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { variableNature, variableType, evaluationComponentType, evaluationEtat } from 'app/config/codifications';
import moment from 'moment';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

export interface IVersionSimulateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const VersionSimulate = (props: IVersionSimulateProps) => {
  const [evaluationLancee, setEvaluationLancee] = useState(false);

  const {
    versionEntity,
    variableEntities,
    loadingVariables,
    updating,
    loadingEvaluation,
    evaluationEntity,
    isConseiller
  } = props;


  useEffect(() => {
    props.getEntity(props.match.params.id);
    props.getVariablesVersion(props.match.params.id);
  }, []);

  const lancerSimulation = (event, errors, values) => {
    if (errors.length === 0) {
      props.reset();
      setEvaluationLancee(true);
      const variableValeurs = Object.keys(values).map(key => { return {code: key, natureCode: variableNature.ENTREE, type: evaluationComponentType.VARIABLE, resultat: values[key]};});
      props.creerEvaluation(
        {versionReference: props.versionEntity.reference, variables: variableValeurs})
    }
  };



  const getValeurVariable = (valeur) => {

    if (!valeur){
      return '';
    }

    if (valeur.length > 30 ){
      return <> <details>
      <summary>Expression évaluée</summary>
      <pre>
        <code>
          {valeur}
        </code>
      </pre>
    </details></>
    }

    return valeur;
  }

  const getVariableField = (id, variable) => {

    if (!variable.liste)
      return (<AvField
        id={id}
        type={variable.typeCode === variableType.DATE ? "date" : variable.typeCode === variableType.NUMBER ? "number" : "text"}
        name={variable.code}
        validate={{
          required: { value: variable.obligatoire, errorMessage: 'Le champ est obligatoire.' },
        }}
      />)

      return (<AvField
        id={id}
        type="select"
        name={variable.code}
        validate={{
          required: { value: variable.obligatoire, errorMessage: 'Le champ est obligatoire.' },
        }}>
          <option value="empty">--- Sélectionner une valeur ---</option>
         {variable.liste.split(";").map(val => (
            <option value={val} key={val}>
              {val}
            </option>
          ))}
        </AvField>
      )
  }

  const handleGenerer = () => {
    props.genererDevis(evaluationEntity);
  };

  const handleReinit = () => {
    props.reset();
  }

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="10">
          <h2 id="tarifApp.version.home.createOrEditLabel">
            <Button tag={Link} id="cancel-save" to={`/catalogue/version/${versionEntity.id}/edit`} replace color="info" style={{marginRight:'20px'}} onClick={() => handleReinit()}>
              <FontAwesomeIcon icon="arrow-left" />
              &nbsp;
              <span className="d-none d-md-inline">Retour</span>
            </Button>
            Simulation du version {versionEntity.reference}
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="10">
          <fieldset>
            <legend className="legend">Saisie des valeurs</legend>

            {loadingVariables ? (
              <p>Chargement des variables..</p>
            ) : (
              <AvForm model={{}} onSubmit={lancerSimulation}>
                {(!variableEntities || (variableEntities && variableEntities.length === 0)) && ("Pas de variable sur cette version")}

                {variableEntities && variableEntities.length > 0 && variableEntities.filter(variable => variable.natureCode === variableNature.ENTREE).map((variable, i) => (
                  <AvGroup key={`variable-${i}`}>
                    <Label for={`variable-nom-${i}`}>
                      {variable.nom}
                    </Label>
                    {getVariableField(`variable-nom-${i}`, variable)}
                  </AvGroup>
                ))}
                &nbsp;
                <Button color="info" id="lancer-entity" type="submit" disabled={updating} className="float-right ">
                  <FontAwesomeIcon icon="tachometer-alt" />
                  &nbsp; Lancer
                </Button>
              </AvForm>
            )}
          </fieldset>
          {evaluationLancee && (
            <fieldset>
              <legend className="legend">Résultat</legend>
                {loadingEvaluation ? (
                  <p>Evaluation en cours..</p>
                ) : (
                  <>
                    {!evaluationEntity.id &&  <p>Evaluation impossible..</p>}
                    {evaluationEntity.id &&  (
                      <>
                        {evaluationEntity.etatCode === 'SUCCESS:EVALUATION_ETAT' && (
                          <>
                            {props.devisGenere && (
                              <div className={'row'} style={{float:'right'}}>
                                <a style={{float:'left',marginRight:'50px', textAlign:'center'}} href={props.devisGenere.url} target="_blank" rel="noopener noreferrer" >
                                  <FontAwesomeIcon icon="file-pdf" size="3x" />
                                  <p>Télécharger le devis généré</p>
                                </a>
                              </div>
                            )}
                            {!props.devisGenere && !props.loadingDevisGeneration && (
                              <Button style={{float:'right'}} id="jhi-confirm-lancer-devis" color="danger" onClick={() => handleGenerer()}>
                                Générer devis &nbsp;

                                <FontAwesomeIcon icon="tachometer-alt" />
                              </Button>
                            )}
                            {!props.devisGenere && props.loadingDevisGeneration && (
                              <div className={'row'} style={{float:'right'}}>
                                Génération en cours
                              </div>
                            )}
                          </>
                        )}
                        <dl className="jh-entity-details">
                          <dt><span id="numero">Numéro</span></dt>
                          <dd>{evaluationEntity.numero}</dd>

                          <dt><span id="codeVersion">Code du Version</span></dt>
                          <dd>{evaluationEntity.versionReference}</dd>

                          <dt><span id="date">Date</span></dt>
                          <dd>{moment(evaluationEntity.dateExecution).format('DD/MM/YYYY hh:mm:ss')}</dd>

                          <dt><span id="description">Créateur</span></dt>
                          <dd>{evaluationEntity.initiateur}</dd>

                          <dt><span id="etat">Etat</span></dt>
                          <dd>{evaluationEntity.etat}</dd>

                          <dt><span id="duree">Durée</span></dt>
                          <dd>{evaluationEntity.duree} ms</dd>

                          { evaluationEntity.etatCode === evaluationEtat.ECHEC && (
                            <>
                              <dt><span id="message">Erreur</span></dt>
                              <dd>{evaluationEntity.errorMessage}</dd>
                            </>
                          )}
                        </dl>

                        <dl className="jh-entity-details">
                          <dt><span id="variables">Variables évaluées</span></dt>

                          {evaluationEntity.variables && evaluationEntity.variables.length > 0 && (
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th>Nature</th>
                                  <th>Code</th>
                                  <th>Résultat</th>
                                  <th>Formule/Valeur par défaut</th>
                                </tr>
                              </thead>
                              <tbody>
                                {evaluationEntity.variables.map((variable, i) => (
                                  <tr key={`entity-${i}`}>
                                    <td>{variable.nature}</td>
                                    <td>{variable.code}</td>
                                    <td>{variable.errorMessage ? variable.errorMessage : variable.resultat}</td>
                                    <td>
                                      {getValeurVariable(variable.valeur)}


                                    </td>

                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          )}
                          {!evaluationEntity.variables || (evaluationEntity.variables && evaluationEntity.variables.length === 0) && ("Aucune variable évaluée")}
                        </dl>

                        <dl className="jh-entity-details">
                          <dt><span id="formules">Formules évaluées</span></dt>

                          {evaluationEntity.formules && evaluationEntity.formules.length > 0 && (
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th>Nature</th>
                                  <th>Formule</th>
                                  <th>Résultat</th>
                                </tr>
                              </thead>
                              <tbody>
                                {evaluationEntity.formules.map((formule, i) => (
                                  <tr key={`entity-${i}`}>
                                    <td>{formule.natureCode}</td>
                                    <td>{formule.valeur}</td>
                                    <td>{formule.errorMessage ? formule.errorMessage : formule.resultat}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          )}
                          {!evaluationEntity.formules || (evaluationEntity.formules && evaluationEntity.formules.length === 0) && ("Aucune formule évaluée")}
                        </dl>
                      </>
                    )}
                  </>
                )}
            </fieldset>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  versionEntity: storeState.version.entity,
  variableEntities: storeState.variable.entities.byVersion[storeState.version.entity.id],
  loadingVersion: storeState.version.loading,
  loadingVariables: storeState.variable.loading,
  updating: storeState.version.updating,
  loadingEvaluation: storeState.evaluation.updating,
  evaluationEntity: storeState.evaluation.entity,
  devisGenere: storeState.document.temporaire.document,
  loadingDevisGeneration: storeState.document.temporaire.loading,
  isConseiller: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.CONSEILLER])
});

const mapDispatchToProps = {
  getEntity,
  getVariablesVersion,
  creerEvaluation,
  genererDevis,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VersionSimulate);
