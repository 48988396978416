import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntity, publishEntity } from './version.reducer';
import { getEntitiesByVersion as getDocumentsVersion,
  deleteEntity as deleteDocument ,
  createEntity as createDocument } from '../document/document.reducer';
import { getEntitiesByVersion as getVariablesVersion,
  deleteEntity as deleteVariable ,
  updateEntity as updateVariable ,
  createEntity as createVariable} from '../variable/variable.reducer';
import { getEntitiesByVersion as getFormulesVersion,
  deleteEntity as deleteFormule ,
  updateEntity as updateFormule ,
  createEntity as createFormule} from '../formule/formule.reducer';
import { getCodes } from '../code/code.reducer';

import ConfirmModal from '../confirm-modal';
import DocumentUpdateModal from '../document/document-update-modal';
import VariableUpdateModal from '../variable/variable-update-modal';
import FormuleUpdateModal from '../formule/formule-update-modal';
import {codeTypes as codification} from 'app/config/codifications'
import VersionPublishModal from './version-publish-modal';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';



export interface IVersionUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const VersionUpdate = (props: IVersionUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [currentEntity, setcurrentEntity] = useState({id:0,versionId:0});
  const [showDialog, setShowDialog] = useState(false);
  const [currentAction, setCurrentAction] = useState('');
  const [currentTypeEntity, setCurrentTypeEntity] = useState();
  const [currentConfirmationMessage, setCurrentConfirmationMessage] = useState(<></>);



  const {
    match,
    versionEntity,
    documentEntities,
    variableEntities,
    formuleEntities,
    loadingVersion,
    loadingDocuments,
    loadingVariables,
    loadingFormules,
    updating,
    updatingDocument,
    updatingVariable,
    updatingFormule,
    etatsVersion,
    categoriesDocument,
    typesVariable,
    naturesVariable,
    typesFormule,
    isConseiller
  } = props;

  const handleClose = () => {
    props.history.push('/catalogue/version');
  };

  const handleDelete = (type, entity) => {
    setCurrentAction('confirm')
    setcurrentEntity(entity);
    setCurrentTypeEntity(type);
    if (type === "document"){
      setCurrentConfirmationMessage(<>Etes vous sur de vouloir supprimer ce document ?<br /><br />Cette action est irréversible.</>)
    } else if (type === "variable"){
      setCurrentConfirmationMessage(<>Etes vous sur de vouloir supprimer cette variable ?<br /><br />Cette action est irréversible.</>)
    }  else if (type === "formule"){
      setCurrentConfirmationMessage(<>Etes vous sur de vouloir supprimer cette formule ?<br /><br />Cette action est irréversible.</>)
    }

    setShowDialog(true);
  }


  const confirmCreerDocument = (values) => {
    props.createDocument(
      {
        categorieCode: values.categorie,
        fileUploadedName: values.file.name,
        taille: values.file.size,
        extension: values.file.name.substring(values.file.name.lastIndexOf('.')+1),
        mimeType: values.file.type,
        objetRefId: versionEntity.id,
        objetRefNom: 'VERSION'
      }
      , values.file );

    setShowDialog(false);
  }

  const confirmCreerVariable = (values) => {
    if (currentEntity.id){
      props.updateVariable( {
          ...values,
          id:currentEntity.id,
          versionId: versionEntity.id
        });
    }else{
      props.createVariable({
        ...values,
        versionId: versionEntity.id
      });
    }
    setShowDialog(false);
  }

  const confirmCreerFormule = (values) => {
    if (currentEntity.id){
      props.updateFormule({
          ...values,
          id:currentEntity.id,
          versionId: versionEntity.id
        });
    }else{
      props.createFormule({
        ...values,
        versionId: versionEntity.id
      });
    }

    setShowDialog(false);
  }

  const confirmPublishVersion = (values) =>{
    props.publishEntity({
      ...versionEntity,
      ...values
    });

    setShowDialog(false);
    props.history.push(`/catalogue/produit/${versionEntity.produitId}/edit`);
  }


  const confirmDelete = () => {
    if (currentTypeEntity === "document"){
      props.deleteDocument(currentEntity.id, currentEntity.versionId)
    } else if (currentTypeEntity === "variable"){
      props.deleteVariable(currentEntity.id, currentEntity.versionId)
    }  else if (currentTypeEntity === "formule"){
      props.deleteFormule(currentEntity.id, currentEntity.versionId)
    }

    setShowDialog(false);
  }

  const handleUpdate = (type, entity) => {
    setCurrentAction(`update-${type}`)
    setcurrentEntity(entity);
    setShowDialog(true);
  }

  useEffect(() => {
    if (etatsVersion.length  === 0)
      props.getCodes({codeType: codification.VERSION_ETAT})

    if (typesVariable.length  === 0)
      props.getCodes({codeType: codification.VARIABLE_TYPE})

    if (naturesVariable.length  === 0)
      props.getCodes({codeType: codification.VARIABLE_NATURE})

    if (typesFormule.length  === 0)
      props.getCodes({codeType: codification.FORMULE_TYPE})

    if (categoriesDocument.length  === 0)
      props.getCodes({codeType: codification.DOCUMENT_CATEGORIE})


    props.getEntity(props.match.params.id);
    props.getDocumentsVersion(props.match.params.id);
    props.getVariablesVersion(props.match.params.id);
    props.getFormulesVersion(props.match.params.id);

  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      // handleClose();
    }
  }, [props.updateSuccess]);


  const openPublishDialog = () => {
    setCurrentAction(`publish-version`)
    setShowDialog(true);
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="10">
          <h2 id="tarifApp.version.home.createOrEditLabel">
            <Button tag={Link} id="cancel-save" to={isConseiller ? `/catalogue/produit/${versionEntity.produitId}` : `/catalogue/produit/${versionEntity.produitId}/edit`} replace color="info" style={{marginRight:'20px'}}>
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Retour</span>
            </Button>
            Création ou Modification d{"'"}un version
              <Button style={{marginLeft:'5px'}} color="cyan" id="save-entity" disabled={updating} className="btn btn-info float-right jh-create-entity" onClick={openPublishDialog}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Publier
              </Button>
              &nbsp;
              <Button  tag={Link} to={`simuler`} replace className="btn btn-cyan float-right jh-create-entity" id="jh-create-entity">
                  <FontAwesomeIcon icon="eye" />
                  &nbsp; Simuler
              </Button>

          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="10">
          <fieldset>
            <legend  className="legend">Documents</legend>

            {loadingDocuments ? (
              <p>Chargement des documents...</p>
            ) : (
              <>
                <div className="table-responsive">
                  {!documentEntities || (documentEntities && documentEntities.length === 0) && ("Aucun document")}

                  {documentEntities && documentEntities.length > 0 && (
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Date de stockage</th>
                          <th>Nom du fichier</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {documentEntities.map((document, i) => (
                          <tr key={`entity-${i}`}>
                            <td>{document.categorie}</td>
                            <td>{document.stockageDate}</td>
                            <td>{document.fileUploadedName}</td>
                            <td className="text-right">
                            <div className="btn-group flex-btn-group-container">
                              <Button href={document.url} target="_blank" color="info" size="sm" style={{marginRight:'5px'}}>
                                <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline"></span>
                              </Button>
                            </div>
                          </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    )}
                </div>
              </>
            )}
          </fieldset>

          <fieldset>
            <legend  className="legend">Variables</legend>

            {loadingVariables ? (
              <p>Chargement des variables...</p>
            ) : (
              <>
                <div className="table-responsive">
                  {!variableEntities || (variableEntities && variableEntities.length === 0) && ("Aucune variable")}

                  {variableEntities && variableEntities.length > 0 && (
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Nature</th>
                          <th>Nom</th>
                          <th>Description</th>
                          <th>Valeur par défaut</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {variableEntities.map((variable, i) => (
                          <tr key={`entity-${i}`}>
                            <td>{variable.nature}</td>
                            <td>{variable.nom}</td>
                            <td>{variable.description}</td>
                            <td>{variable.defaultValue}</td>
                            <td className="text-right">
                              <div className="btn-group flex-btn-group-container">
                                <Button onClick={() => handleUpdate("variable", variable)} color="info" size="sm" style={{marginRight:'5px'}}>
                                  <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline"></span>
                                </Button>
                                <Button onClick={() => handleDelete("variable", variable)} color="danger" size="sm">
                                  <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline"></span>
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    )}
                </div>
                <Button onClick={() => handleUpdate("variable", {})} className="btn btn-cyan float-right jh-create-entity" id="jh-create-entity">
                  <FontAwesomeIcon icon="plus" />
                  &nbsp; Nouvelle variable
                </Button>
              </>
            )}
          </fieldset>

          <fieldset>
            <legend  className="legend">Formules</legend>

            {loadingFormules ? (
              <p>Chargement des formules...</p>
            ) : (
              <>
                <div className="table-responsive">
                  {!formuleEntities || (formuleEntities && formuleEntities.length === 0) && ("Aucune formule")}

                  {formuleEntities && formuleEntities.length > 0 && (
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Nom</th>
                          <th>Description</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {formuleEntities.map((formule, i) => (
                          <tr key={`entity-${i}`}>
                            <td>{formule.type}</td>
                            <td>{formule.nom}</td>
                            <td>{formule.description}</td>
                            <td className="text-right">
                              <div className="btn-group flex-btn-group-container">
                                <Button onClick={() => handleUpdate("formule", formule)} color="info" size="sm" style={{marginRight:'5px'}}>
                                  <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline"></span>
                                </Button>
                                <Button onClick={() => handleDelete("formule", formule)} color="danger" size="sm">
                                  <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline"></span>
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    )}
                </div>
                <Button onClick={() => handleUpdate("formule", {})} className="btn btn-cyan float-right jh-create-entity" id="jh-create-entity">
                  <FontAwesomeIcon icon="plus" />
                  &nbsp; Nouvelle formule
                </Button>
              </>
            )}
          </fieldset>
        </Col>
      </Row>

      <ConfirmModal
        showModal={showDialog && currentAction === "confirm"}
        title="Confirmation de la suppression"
        message={currentConfirmationMessage}
        handleValid={() => {confirmDelete()}}
        handleClose={() => {setShowDialog(false)}}
      />

      <DocumentUpdateModal
        showModal={showDialog && currentAction === "update-document"}
        handleClose={() => {setShowDialog(false)}}
        handleValid={confirmCreerDocument}
        categories={categoriesDocument}
        updating={updatingDocument}
      />

      <VariableUpdateModal
        showModal={showDialog && currentAction === "update-variable"}
        handleClose={() => {setShowDialog(false)}}
        handleValid={confirmCreerVariable}
        types={typesVariable}
        natures={naturesVariable}
        updating={updatingVariable}
        entity={currentEntity}
        variablesVersion={variableEntities}
      />

      <FormuleUpdateModal
        showModal={showDialog && currentAction === "update-formule"}
        handleClose={() => {setShowDialog(false)}}
        handleValid={confirmCreerFormule}
        types={typesFormule}
        updating={updatingFormule}
        entity={currentEntity}
        variablesVersion={variableEntities}
      />

      <VersionPublishModal
        showModal={showDialog && currentAction === "publish-version"}
        handleClose={() => {setShowDialog(false)}}
        handleValid={confirmPublishVersion}
        updating={updating}
        entity={versionEntity}
      />


    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  versionEntity: storeState.version.entity,
  documentEntities: storeState.document.entities.byVersion[storeState.version.entity.id],
  variableEntities: storeState.variable.entities.byVersion[storeState.version.entity.id],
  formuleEntities: storeState.formule.entities[storeState.version.entity.id],
  etatsVersion: storeState.code.entities && storeState.code.entities[codification.VERSION_ETAT] ? storeState.code.entities[codification.VERSION_ETAT] : [],
  categoriesDocument: storeState.code.entities && storeState.code.entities[codification.DOCUMENT_CATEGORIE] ? storeState.code.entities[codification.DOCUMENT_CATEGORIE] : [],
  typesVariable: storeState.code.entities && storeState.code.entities[codification.VARIABLE_TYPE] ? storeState.code.entities[codification.VARIABLE_TYPE] : [],
  naturesVariable: storeState.code.entities && storeState.code.entities[codification.VARIABLE_NATURE] ? storeState.code.entities[codification.VARIABLE_NATURE] : [],
  typesFormule: storeState.code.entities && storeState.code.entities[codification.FORMULE_TYPE] ? storeState.code.entities[codification.FORMULE_TYPE] : [],
  loadingVersion: storeState.version.loading,
  loadingDocuments: storeState.document.loading,
  loadingVariables: storeState.variable.loading,
  loadingFormules: storeState.formule.loading,
  updating: storeState.version.updating,
  updatingDocument: storeState.document.updating,
  updatingVariable: storeState.variable.updating,
  updatingFormule: storeState.formule.updating,
  updateSuccess: storeState.version.updateSuccess,
  isConseiller: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.CONSEILLER])
});

const mapDispatchToProps = {
  getEntity,
  getDocumentsVersion,
  getVariablesVersion,
  getFormulesVersion,
  deleteDocument,
  createDocument,
  deleteFormule,
  createFormule,
  updateFormule,
  deleteVariable,
  createVariable,
  updateVariable,
  getCodes,
  publishEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VersionUpdate);
