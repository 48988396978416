import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntity } from './produit.reducer';
import { getEntitiesByProduit as getVersionsProduit } from '../version/version.reducer';
import { getEntitiesByProduit as getVariablesProduit } from '../variable/variable.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import moment from 'moment';
import {versionEtat} from 'app/config/codifications';

export interface IProduitDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ProduitDetail = (props: IProduitDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
    props.getVersionsProduit(props.match.params.id);

    if(!props.variableEntities)
      props.getVariablesProduit(props.match.params.id);

  }, []);

  const { produitEntity, versions,variableEntities, loadingProduit, loadingVersion,loadingVariable, isConseiller } = props;

  return (
      <Row className="justify-content-center">
        <Col md="10">
            <h2>
              <Button tag={Link} to="/catalogue/produit" replace color="info" style={{marginRight:'20px'}}>
                <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Retour</span>
              </Button>
              Consultation du produit {`"${produitEntity.nom}"` }
            </h2>

            <fieldset>
              <legend className="legend">Informations générales</legend>
              <dl className="jh-entity-details">
              {!loadingProduit ? (
                <>
                  <dt>
                    <span id="nom">Nom</span>
                  </dt>
                  <dd>{produitEntity.nom}</dd>
                  <dt>
                    <span id="description">Description</span>
                  </dt>
                  <dd>{produitEntity.description}</dd>
                </>) : ( <p>Chargement des informations du produit...</p>)}
              </dl>
            </fieldset>

            <fieldset>
              <legend className="legend">Versions</legend>
              <dl className="jh-entity-details">
              {!loadingVersion ? (
                <>
                {versions && versions.length > 0 && (
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Référence</th>
                        <th>Etat</th>
                        <th>Date de publication</th>
                        <th>Publieur</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {versions.map((version, i) => (
                        <tr key={`entity-${i}`}>
                          <td>{version.reference}</td>
                          <td>{version.etat}</td>
                          <td>{version.publicationDate ? moment(version.publicationDate).format("DD/MM/YYYY HH:MM:SS") : ""}</td>
                          <td>{version.publieur}</td>
                          <td>
                            {(version.etatCode !== versionEtat.BROUILLON || !isConseiller) && (
                              <Button tag={Link} to={`/catalogue/version/${version.id}`} color="info" size="sm" style={{marginRight:'5px'}}>
                                <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">Consulter</span>
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  )}
                {!versions || (versions && versions.length === 0) && ("Aucune version")}
                </>) : ( <p>Chargement des versions du produit...</p>)}
              </dl>
            </fieldset>

            <fieldset>
            <legend className="legend">Informations prospects non tarifantes</legend>
            <dl className="jh-entity-details">
            {!loadingVariable ? (
              <>
              {variableEntities && variableEntities.length > 0 && (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Nom</th>
                      <th>Description</th>
                      <th>Valeur par défaut</th>
                    </tr>
                  </thead>
                  <tbody>
                    {variableEntities.map((variable, i) => (
                      <tr key={`entity-${i}`}>
                        <td>{variable.nom}</td>
                        <td>{variable.description}</td>
                        <td>{variable.defaultValue}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                )}
              {!variableEntities || (variableEntities && variableEntities.length === 0) && ("Aucune information")}
              </>) : ( <p>Chargement des informations nécéssaires du prospect...</p>)}
            </dl>
          </fieldset>
        </Col>
      </Row>
  );
};

const mapStateToProps = ({ produit, version, variable, authentication }: IRootState) => ({
  produitEntity: produit.entity,
  versions: version.entities && produit.entity ? version.entities[produit.entity.id]:undefined,
  variableEntities: variable.entities.byProduit[produit.entity.id],
  loadingProduit: produit.loading,
  loadingVersion: version.loading,
  loadingVariable: variable.loading,
  isConseiller: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.CONSEILLER])
});

const mapDispatchToProps = { getEntity, getVersionsProduit, getVariablesProduit };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProduitDetail);
