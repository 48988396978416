import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';

import { NavDropdown } from './menu-components';

const accountMenuItemsAuthenticated = (
  <>
    <MenuItem icon="wrench" to="/account/settings">
      Mon compte
    </MenuItem>
    <MenuItem icon="lock" to="/account/password">
      Sécurité
    </MenuItem>
    <MenuItem icon="sign-out-alt" to="/logout">
      Déconnexion
    </MenuItem>
  </>)


const accountMenuItems = (
  <MenuItem id="login-item" icon="sign-in-alt" to="/login">
    Se connecter
  </MenuItem>
);

export const AccountMenu = ({ isAuthenticated = false }) => (
  <NavDropdown icon="user" name="Mon compte" id="account-menu">
    {isAuthenticated ? accountMenuItemsAuthenticated : accountMenuItems}
  </NavDropdown>
);

export default AccountMenu;
