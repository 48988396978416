import { IVariable } from 'app/shared/model/variable.model';
import { IFormule } from 'app/shared/model/formule.model';
import { IDocument } from './document.model';

export interface IVersion {
  id?: number;
  publieur?: string;
  reference?: string;
  note?: string;
  publicationDate?: number;
  etat?: string;
  etatCode?: string;
  produitId?: number;
  variables?: IVariable[];
  documents?: IDocument[];
  formules?: IFormule[];
}

export const defaultValue: Readonly<IVersion> = {};
