import { IEntity } from './entity.model';

export interface IVariable extends IEntity {
  nom?: string;
  description?: string;
  nature?: string;
  natureCode?: string;
  defaultValue?: string;
  formule?: string;
  type?: string;
  typeCode?: string;
  liste?: string;
  obligatoire?: boolean;
  orderIndex?: number;
  criteres?: string,
  produitId?: number;
}

export const defaultValue: Readonly<IVariable> = {
  obligatoire: false,
};
