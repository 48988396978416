import axios from 'axios';
import { ICrudGetAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IFormule, defaultValue } from 'app/shared/model/formule.model';
import { IVersionDeleteAction } from 'app/shared/util/custom-action.type';

export const ACTION_TYPES = {
  FETCH_FORMULE_BY_PRODUIT_LIST: 'formule/FETCH_FORMULE_BY_PRODUIT_LIST',
  FETCH_FORMULE: 'formule/FETCH_FORMULE',
  CREATE_FORMULE: 'formule/CREATE_FORMULE',
  UPDATE_FORMULE: 'formule/UPDATE_FORMULE',
  DELETE_FORMULE: 'formule/DELETE_FORMULE',
  RESET: 'formule/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: {},
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

export type FormuleState = Readonly<typeof initialState>;

// Reducer

export default (state: FormuleState = initialState, action): FormuleState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_FORMULE_BY_PRODUIT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_FORMULE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_FORMULE):
    case REQUEST(ACTION_TYPES.UPDATE_FORMULE):
    case REQUEST(ACTION_TYPES.DELETE_FORMULE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_FORMULE_BY_PRODUIT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_FORMULE):
    case FAILURE(ACTION_TYPES.UPDATE_FORMULE):
    case FAILURE(ACTION_TYPES.CREATE_FORMULE):
    case FAILURE(ACTION_TYPES.DELETE_FORMULE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_FORMULE_BY_PRODUIT_LIST):
      return {
        ...state,
        loading: false,
        entities: {
          ...state.entities,
          [action.meta]: [...action.payload.data],
        },
      };
    case SUCCESS(ACTION_TYPES.FETCH_FORMULE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_FORMULE):
    case SUCCESS(ACTION_TYPES.UPDATE_FORMULE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_FORMULE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/formules';

// Actions
export const getEntitiesByVersion = versionId => {
  const requestUrl = `${apiUrl}/byVersion/${versionId}`;
  return {
    type: ACTION_TYPES.FETCH_FORMULE_BY_PRODUIT_LIST,
    payload: axios.get<IFormule>(requestUrl),
    meta: versionId,
  };
};

export const getEntity: ICrudGetAction<IFormule> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_FORMULE,
    payload: axios.get<IFormule>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IFormule> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_FORMULE,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntitiesByVersion(entity.versionId));
  return result;
};

export const updateEntity: ICrudPutAction<IFormule> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_FORMULE,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });

  dispatch(getEntitiesByVersion(entity.versionId));
  return result;
};

export const deleteEntity: IVersionDeleteAction<IFormule> = (id, idVersion) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_FORMULE,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntitiesByVersion(idVersion));
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
