import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,  Row, Col, Label, Alert} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { IVariable } from 'app/shared/model/variable.model';
import {variableType, variableNature} from 'app/config/codifications'
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "@webscopeio/react-textarea-autocomplete/style.css";


export interface IInfoUpdateModalProps {
  showModal: boolean;
  handleValid: Function;
  handleClose: Function;
  entity: IVariable;
  updating: boolean;
  variablesVersion: any;
}

export interface IInfoUpdateModalState {
  formatedNom: string;
  liste: string;
}


class InfoUpdateModal extends React.Component<IInfoUpdateModalProps, IInfoUpdateModalState> {
  readonly state: IInfoUpdateModalState = { formatedNom: "", liste:"" };

  constructor(props) {
    super(props);

    this.setState({
      formatedNom: this.formatNom(props.entity.nom),
      liste: props.entity.liste ? props.entity.liste.replaceAll(";","\n") : ""
    })

    this.changeCode = this.changeCode.bind(this);
    this.close = this.close.bind(this);
  }

  componentDidUpdate(prevProps) {
    let state = {};

    if (this.props.entity.nom !== prevProps.entity.nom) {
      state = {...state, formatedNom: this.formatNom(this.props.entity.nom)};
    }

    if (this.props.entity.liste !== prevProps.entity.liste) {
      state = {...state, liste: this.props.entity.liste ? this.props.entity.liste.replace(/;/g,"\n") : ""};
    }

    if (Object.keys(state).length>0) {
      this.setState({
        ...state
      })
    }

  }

  formatNom = (value) => {
    const rules = {
			a:"àáâãäå",
			A:"ÀÁÂ",
			e:"èéêë",
			E:"ÈÉÊË",
			i:"ìíîï",
			I:"ÌÍÎÏ",
			o:"òóôõöø",
			O:"ÒÓÔÕÖØ",
			u:"ùúûü",
			U:"ÙÚÛÜ",
			y:"ÿ",
			c: "ç",
			C:"Ç",
			n:"ñ",
			N:"Ñ"
    };

    if (value) {
      value =value.replaceAll(' ','_').replaceAll('-','_').replaceAll("'",'');
      Object.keys(rules).forEach(rule =>
      {
        for(const car of rules[rule]){
          value = value.replaceAll(car, rule);
        }
      })

      return value.toUpperCase();
    }
    return "";
  }


  handleSubmit = (event, errors, values) => {
    const { handleValid } = this.props;
    const { liste } = this.state;

    if (errors.length === 0){
      if (liste){
        values.liste = liste.replace(/\n/g,";");
      }

      values.natureCode = variableNature.ENTREE
      values.typeCode = variableType.STRING;

      this.setState({
        formatedNom: "",
        liste: ""
      })


      handleValid(values);
    }
  };

  changeCode = (target) =>{
    this.setState({
      formatedNom: this.formatNom(target.value)})
  };


  changeListe = (value) =>{
    this.setState({
      liste: value})
  }


  close = () => {

    this.setState({
      formatedNom: "",
      liste: ""
    })

    this.props.handleClose();
  }

  render() {
    const { showModal, updating, entity, variablesVersion} = this.props;
    const { formatedNom, liste } = this.state;
    const isNew = entity.id === 0 || !entity.id
    const Item = ({ entity: { name, char } }) => <div>{`${name}: ${char}`}</div>;
    const Loading = ({ data }) => <div>Loading</div>;

    return (
      <Modal style={{minWidth:'1000px'}} isOpen={showModal} toggle={() => this.close()} backdrop="static" id="variable-update-page" autoFocus={false}>
        <AvForm model={isNew ? {}:entity} onSubmit={this.handleSubmit}>
          <ModalHeader toggle={() => this.close()}>{isNew ? "Création d'une nouvelle information" : "Modification d'une information"}</ModalHeader>
          <ModalBody id="tarifApp.version.delete.question">
            <Row className="justify-content-center">
              <Col md="5">
                  <AvGroup>
                    <Label id="nomLabel" for="variable-nom">
                      Nom
                    </Label>
                    <AvField
                        id="variable-nom"
                        type="text"
                        readOnly={!isNew}
                        name="nom"
                        validate={{
                          required: { value: true, errorMessage: 'Le champ est obligatoire.' },
                        }}
                        onChange={ (e) => this.changeCode(e.target) }
                      />
                  </AvGroup>
                  <AvGroup>
                    <Label id="typesLabel" for="variable-mandatory">
                      Obligatoire
                    </Label>
                    <AvInput id="variable-mandatory" type="select"  name="obligatoire"
                      validate={{
                        required: { value: true, errorMessage: 'Le champ est obligatoire' },
                      }}>
                      <option value="empty">--- Sélectionner une valeur ---</option>
                      <option value="true">Oui</option>
                      <option value="false">Non</option>
                    </AvInput>
                  </AvGroup>
                  <AvGroup>
                    <Label id="codeLabel" for="variable-code">
                      Code
                    </Label>
                    <AvField
                        id="variable-code"
                        type="text"
                        readOnly={true}
                        value={formatedNom}
                        name="code"
                        validate={{
                          required: { value: true, errorMessage: 'Le champ est obligatoire.' },
                        }}
                      />
                </AvGroup>
                <AvGroup>
                  <Label id="descriptionLabel" for="variable-description">
                    Description
                  </Label>
                  <AvField
                      id="variable-description"
                      type="textarea"
                      name="description"
                      validate={{
                        required: { value: true, errorMessage: 'Le champ est obligatoire.' },
                      }}
                    />
                </AvGroup>
                </Col>
              <Col md="5">
                    <AvGroup>
                      <Label id="defaultValueLabel" for="variable-defaultValue">
                        Valeur par défaut
                      </Label>
                      <AvInput placeholder="Veuillez saisir du texte" id="variable-defaultValue" type="text"  name="defaultValue" />
                    </AvGroup>

                    <AvGroup>
                      <Label id="listeLabel" for="variable-liste">
                        Liste de valeurs autorisées
                      </Label>
                      <AvInput id="variable-liste" type="textarea"  name="liste" rows={5} value={liste}
                        onChange={ (e) => this.changeListe(e.target.value) } />
                    </AvGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.close()}>
              <FontAwesomeIcon icon="ban" />
              &nbsp; Annuler
            </Button>
            <Button color="info" id="save-entity" type="submit" disabled={updating} className="float-right ">
              <FontAwesomeIcon icon="save" />
              &nbsp; Enregistrer
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }
}

export default InfoUpdateModal;
