import axios from 'axios';
import { ICrudGetAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IVariable, defaultValue } from 'app/shared/model/variable.model';
import { IVersionDeleteAction } from 'app/shared/util/custom-action.type';

export const ACTION_TYPES = {
  FETCH_VARIABLE_BY_VERSION_LIST: 'variable/FETCH_VARIABLE_BY_VERSION_LIST',
  FETCH_VARIABLE_BY_PRODUIT_LIST: 'variable/FETCH_VARIABLE_BY_PRODUIT_LIST',
  FETCH_VARIABLE: 'variable/FETCH_VARIABLE',
  CREATE_VARIABLE: 'variable/CREATE_VARIABLE',
  UPDATE_VARIABLE: 'variable/UPDATE_VARIABLE',
  DELETE_VARIABLE: 'variable/DELETE_VARIABLE',
  RESET: 'variable/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: {
    byProduit:{},
    byVersion:{}
  },
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

export type VariableState = Readonly<typeof initialState>;

// Reducer

export default (state: VariableState = initialState, action): VariableState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_VARIABLE_BY_VERSION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_VARIABLE_BY_PRODUIT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_VARIABLE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_VARIABLE):
    case REQUEST(ACTION_TYPES.UPDATE_VARIABLE):
    case REQUEST(ACTION_TYPES.DELETE_VARIABLE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_VARIABLE_BY_VERSION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_VARIABLE_BY_PRODUIT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_VARIABLE):
    case FAILURE(ACTION_TYPES.CREATE_VARIABLE):
    case FAILURE(ACTION_TYPES.UPDATE_VARIABLE):
    case FAILURE(ACTION_TYPES.DELETE_VARIABLE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_VARIABLE_BY_VERSION_LIST):
      return {
        ...state,
        loading: false,
        entities: {
          ...state.entities,
          byVersion: {
            ...state.entities.byVersion,
            [action.meta]: [...action.payload.data]
          }
        },
      };
      case SUCCESS(ACTION_TYPES.FETCH_VARIABLE_BY_PRODUIT_LIST):
        return {
          ...state,
          loading: false,
          entities: {
            ...state.entities,
            byProduit: {
              ...state.entities.byProduit,
              [action.meta]: [...action.payload.data]
            }
          },
        };
      case SUCCESS(ACTION_TYPES.FETCH_VARIABLE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_VARIABLE):
    case SUCCESS(ACTION_TYPES.UPDATE_VARIABLE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_VARIABLE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/variables';

// Actions

export const getEntitiesByVersion = versionId => {
  const requestUrl = `${apiUrl}/byVersion/${versionId}`;
  return {
    type: ACTION_TYPES.FETCH_VARIABLE_BY_VERSION_LIST,
    payload: axios.get<IVariable>(requestUrl),
    meta: versionId,
  };
};


export const getEntitiesByProduit = produitId => {
  const requestUrl = `${apiUrl}/byProduit/${produitId}`;
  return {
    type: ACTION_TYPES.FETCH_VARIABLE_BY_PRODUIT_LIST,
    payload: axios.get<IVariable>(requestUrl),
    meta: produitId,
  };
};

export const getEntity: ICrudGetAction<IVariable> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_VARIABLE,
    payload: axios.get<IVariable>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IVariable> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_VARIABLE,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });

  if (entity.produitId){
    dispatch(getEntitiesByProduit(entity.produitId));
  }else{
    dispatch(getEntitiesByVersion(entity.versionId));
  }
  return result;
};

export const updateEntity: ICrudPutAction<IVariable> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_VARIABLE,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });

  if (entity.produitId){
    dispatch(getEntitiesByProduit(entity.produitId));
  }else{
    dispatch(getEntitiesByVersion(entity.versionId));
  }
  return result;
};

export const deleteEntity: IVersionDeleteAction<IVariable> = (id, idVersion) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_VARIABLE,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntitiesByVersion(idVersion));
  return result;
};

export const deleteEntityProduit: IVersionDeleteAction<IVariable> = (id, idProduit) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_VARIABLE,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntitiesByProduit(idProduit));
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
