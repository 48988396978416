import { Moment } from 'moment';
import { IEntity } from './entity.model';

export interface IDocument extends IEntity {
  filename?: string;
  fileUploadedName?: string;
  categorie?: string;
  categorieCode?: string;
  taille?: number;
  extension?: string;
  url?: string;
  mimeType?: string;
  stockageDate?: string;
  objetRefId?: number;
  objetRefNom?: string;
}

export const defaultValue: Readonly<IDocument> = {};
