import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table, Row, Col, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntities, searchEntities } from './devis.reducer';
import { AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import moment from 'moment';

export interface IDevisProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const Devis = (props: IDevisProps) => {
  useEffect(() => {
    // props.getEntities();
  }, []);

  const handleSearch = (event, errors, values) => {
    props.searchEntities(values);
  };

  const { devisList, match, loading, isConseiller } = props;
  return (

      <fieldset>
        <legend className="legend">Liste des devis</legend>

        <div className="alert alert-info">
          <AvForm model={{numero:'', nom:'', creationDateFrom:'', creationDateTo:'', produit:'', createur:''}} onSubmit={handleSearch}>
            <Row className="justify-content-left">
              <Col md="2">
                <AvGroup>
                  <Label id="noteLabel" for="version-note">
                    Numéro
                  </Label>
                  <AvInput id="version-note" type="text"  name="numero" />
                </AvGroup>
              </Col>
              <Col md="2">
                <AvGroup>
                  <Label id="noteLabel" for="version-note">
                    Créateur
                  </Label>
                  <AvInput id="version-note" type="text"  name="createur" />
                </AvGroup>
              </Col>
              <Col md="2">
                <AvGroup>
                  <Label id="noteLabel" for="version-note">
                    Produit
                  </Label>
                  <AvInput id="version-note" type="text"  name="produit" />
                </AvGroup>
              </Col>
              <Col md="2">
                <AvGroup>
                  <Label id="noteLabel" for="version-note">
                    Nom du prospect
                  </Label>
                  <AvInput id="version-note" type="text"  name="nom" />
                </AvGroup>
              </Col>
              <Col md="2">
                <AvGroup>
                  <Label id="noteLabel" for="version-note">
                    Créé depuis le
                  </Label>
                  <AvInput id="version-note" type="date"  name="creationDateFrom" />
                </AvGroup>
              </Col>
              <Col md="2">
                <AvGroup>
                  <Label id="noteLabel" for="version-note">
                    Jusqu{"'"}au
                  </Label>
                  <AvInput id="version-note" type="date"  name="creationDateTo" />
                </AvGroup>
              </Col>
            </Row>
            <Row className="justify-content-right">
              <Col md="12">
              <Button color="primary" id="save-entity" type="submit" className="float-right ">
                <FontAwesomeIcon icon="search" />
                &nbsp; Rechercher
              </Button>
              </Col>
            </Row>
          </AvForm>
        </div>
        {loading &&  <div className="alert alert-warning">Recherche en cours</div>}
        {!loading &&
          devisList && devisList.length ===0 && <div className="alert alert-warning">Aucune devis trouvé</div>
        }
        {
          !loading && !devisList && <div className="alert alert-warning">Veuillez lancer la recherche</div>
        }

        { !loading && devisList && devisList.length > 0 && (
          <Table responsive>
            <thead>
              <tr>
                <th>Numéro</th>
                <th>Nom du prospect</th>
                <th>Produit</th>
                <th>Date de création</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {devisList.map((devis, i) => (
                <tr key={`devis-${i}`}>
                  <td>{devis.numero}</td>
                  <td>{devis.prospect.nom}</td>
                  <td>{devis.evaluation.produit}</td>
                  <td>{moment(devis.creationDate).format("DD/MM/YYYY HH:MM")}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${devis.id}`} color="info" size="sm" style={{marginRight:'5px'}}>
                        <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">Consulter</span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </fieldset>
  );
};

const mapStateToProps = ({ devis, authentication }: IRootState) => ({
  devisList: devis.entities,
  loading: devis.loading,
  isConseiller: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.CONSEILLER])
});

const mapDispatchToProps = {
  getEntities,
  searchEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Devis);
