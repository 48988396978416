import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntity } from './version.reducer';
import { getEntitiesByVersion as getDocumentsVersion } from '../document/document.reducer';
import { getEntitiesByVersion as getVariablesVersion } from '../variable/variable.reducer';
import { getEntitiesByVersion as getFormulesVersion } from '../formule/formule.reducer';
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
library.add(faFilePdf);
import {versionEtat} from 'app/config/codifications'
import moment from 'moment';

export interface IVersionDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const VersionDetail = (props: IVersionDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);

    if(!props.documentEntities)
      props.getDocumentsVersion(props.match.params.id);

    if(!props.variableEntities)
      props.getVariablesVersion(props.match.params.id);

    if(!props.formuleEntities)
      props.getFormulesVersion(props.match.params.id);
  }, []);

  const { versionEntity, match, documentEntities, variableEntities,formuleEntities, loadingVersion, loadingDocument, loadingVariable, loadingFormule, isConseiller } = props;

  return (
    <Row className="justify-content-center">
      <Col md="10">
          <h2>

            <Button tag={Link} to={isConseiller ? `/catalogue/produit/${versionEntity.produitId}` : `/catalogue/produit/${versionEntity.produitId}/edit`} replace color="info" style={{marginRight:'20px'}}>
              <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Retour</span>
            </Button>
            Consultation de la version {`"${versionEntity.reference}"` }
          </h2>

          <fieldset>
            <legend className="legend">Informations générales</legend>
            <dl className="jh-entity-details">
            {(!loadingVersion && !loadingDocument) ? (
              <>
                <dt>
                  <span id="description">Description</span>
                </dt>
                <dd>{versionEntity.note}</dd>
                <dt>
                  <span id="etat">Etat</span>
                </dt>
                <dd>{versionEntity.etat}</dd>
                {versionEntity.etatCode !== versionEtat.BROUILLON && (
                  <>
                    <dt>
                      <span id="publicationDate">Date de publication</span>
                    </dt>
                    <dd>{moment(versionEntity.publicationDate).format("DD/MM/YYYY HH:MM:SS")}</dd>
                    <dt>
                      <span id="publieur">Publieur</span>
                    </dt>
                    <dd>{versionEntity.publieur}</dd>
                  </>
                )}

                <dt>
                  <span id="document">Documents</span>
                </dt>
                {documentEntities && documentEntities.length >0 && (
                  <dd style={{paddingTop:'5px'}}>
                    {documentEntities.map((document, i) => (
                      <a style={{float:'left',marginRight:'50px', textAlign:'center'}} key={`entity-${i}`} href={document.url} target="_blank" rel="noopener noreferrer" >
                        <FontAwesomeIcon icon="file-pdf" size="3x" title={document.categorie} />
                        <p>{document.categorie}</p>
                      </a>

                    ))}
                  </dd>
                )}
                {!documentEntities || (documentEntities && documentEntities.length === 0) && ("Aucun document")}
              </>) : ( <p>Chargement des informations du version...</p>)}
            </dl>
          </fieldset>

          <fieldset>
            <legend className="legend">Variables</legend>
            <dl className="jh-entity-details">
            {!loadingVariable ? (
              <>
              {variableEntities && variableEntities.length > 0 && (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Nature</th>
                      <th>Nom</th>
                      <th>Description</th>
                      <th>Valeur par défaut</th>
                    </tr>
                  </thead>
                  <tbody>
                    {variableEntities.map((variable, i) => (
                      <tr key={`entity-${i}`}>
                        <td>{variable.nature}</td>
                        <td>{variable.nom}</td>
                        <td>{variable.description}</td>
                        <td>{variable.defaultValue}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                )}
              {!variableEntities || (variableEntities && variableEntities.length === 0) && ("Aucune variable")}
              </>) : ( <p>Chargement des variables du version...</p>)}
            </dl>
          </fieldset>

          <fieldset>
            <legend className="legend">Formules</legend>
            <dl className="jh-entity-details">
            {!loadingFormule ? (
              <>
              {formuleEntities && formuleEntities.length > 0 && (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Nom</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formuleEntities.map((formule, i) => (
                      <tr key={`entity-${i}`}>
                        <td>{formule.type}</td>
                        <td>{formule.nom}</td>
                        <td>{formule.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                )}
              {!formuleEntities || (formuleEntities && formuleEntities.length === 0) && ("Aucune formule")}
              </>) : ( <p>Chargement des formules du version...</p>)}
            </dl>
          </fieldset>
          {(!isConseiller || versionEntity.etatCode === versionEtat.PUBLIE) && (
              <Button  tag={Link} to={`${match.url}/simuler`} replace className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
                  <FontAwesomeIcon icon="eye" />
                  &nbsp; Faire une simulation
              </Button>
            )}
      </Col>
    </Row>

  );
};

const mapStateToProps = ({ version, document, variable, formule, authentication }: IRootState) => ({
  versionEntity: version.entity,
  documentEntities: document.entities.byVersion[version.entity.id],
  variableEntities: variable.entities.byVersion[version.entity.id],
  formuleEntities: formule.entities[version.entity.id],
  loadingVersion: version.loading,
  loadingDocument: document.loading,
  loadingVariable: variable.loading,
  loadingFormule: formule.loading,
  isConseiller: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.CONSEILLER])
});

const mapDispatchToProps = { getEntity, getDocumentsVersion, getVariablesVersion, getFormulesVersion };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VersionDetail);
