import { IEvaluation } from "./evaluation.model";
import { IProspect } from "./prospect.model";


export interface IDevis {
  id?: number;
  numero?: string;
  creationDate?: Date;
  createur?: string;
  prospect?: IProspect;
  evaluation?: IEvaluation;
}

export const defaultValue: Readonly<IDevis> = {};
