import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntity } from './devis.reducer';
import { getEntitiesByDevis as getDocumentsDevis } from '../../entities/document/document.reducer';
import moment from 'moment';
import { evaluationEtat } from 'app/config/codifications';

export interface IDevisDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const DevisDetail = (props: IDevisDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);

    if(!props.documentEntities)
      props.getDocumentsDevis(props.match.params.id);

  }, []);

  const { devisEntity, documentEntities, loadingDocument, loadingDevis } = props;

  const formatInformationsProspect = (informations) => {
    return JSON.stringify(JSON.parse(informations).map(infos => (infos.code + " : " + infos.valeur )), null, 4);
  }

  return (
      <Row className="justify-content-center">
        <Col md="10">
            <h2>
              <Button tag={Link} to="/production/devis" replace color="info" style={{marginRight:'20px'}}>
                <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Retour</span>
              </Button>
              Consultation du produit {`"${devisEntity.numero}"` }
            </h2>

            <fieldset>
              <legend className="legend">Informations générales</legend>
              <dl className="jh-entity-details">
              {!loadingDevis  ? (
                <>
                  <dt><span id="numero">Numéro</span></dt>
                  <dd>{devisEntity.numero}</dd>

                  <dt><span id="creationDate">Date de création</span></dt>
                  <dd>{moment(devisEntity.creationDate).format("DD/MM/YYYY HH:MM")}</dd>

                  <dt><span id="createur">Créateur</span></dt>
                  <dd>{devisEntity.createur}</dd>

                  {devisEntity.evaluation && (<>
                    <dt><span id="produit">Produit</span></dt>
                    <dd>{devisEntity.evaluation.produit}</dd>
                  </>)}

                  {devisEntity.prospect && (
                  <>
                    <dt><span id="nomProspect">Informations du prospect</span></dt>
                    <dd>
                      {devisEntity.prospect.informations && (
                        <>
                          <details>
                            <summary>{devisEntity.prospect.nom}</summary>
                            <pre>
                              <code>
                                {formatInformationsProspect(devisEntity.prospect.informations)}
                              </code>
                            </pre>
                          </details>
                        </>
                      )}
                    </dd>

                    {devisEntity.prospect.mail && (
                      <><dt><span id="mailProspect">Mail du prospect</span></dt>
                      <dd>{devisEntity.prospect.mail}</dd></>
                    )}

                    {devisEntity.prospect.tel && (
                      <><dt><span id="telProspect">Téléphone du prospect</span></dt>
                      <dd>{devisEntity.prospect.tel}</dd></>
                    )}
                  </>
                  )}

                </>) : ( <p>Chargement des informations du devis...</p>)}
              </dl>
            </fieldset>
            <fieldset>
              <legend className="legend">Documents</legend>
              <dl className="jh-entity-details">
              {!loadingDocument  ? (<>

                { documentEntities && documentEntities.length >0 && (
                  <dd style={{paddingTop:'5px'}}>
                    {documentEntities.map((document, i) => (
                      <a style={{float:'left',marginRight:'50px', textAlign:'center'}} key={`entity-${i}`} href={document.url} target="_blank" rel="noopener noreferrer" >
                        <FontAwesomeIcon icon="file-pdf" size="3x" title={document.categorie} />
                        <p>{document.categorie}</p>
                      </a>

                    ))}
                  </dd>
                )}
                {!documentEntities || (documentEntities && documentEntities.length === 0) && ("Aucun document")}
                </>) : ( <p>Chargement des documents du devis...</p>)}
              </dl>
            </fieldset>
            {devisEntity.evaluation && (
            <fieldset>
              <legend className="legend">Résultat</legend>
              <dl className="jh-entity-details">
                <dt><span id="numero">Numéro</span></dt>
                <dd>{devisEntity.evaluation.numero}</dd>

                <dt><span id="refVersion">Référence Version</span></dt>
                <dd>{devisEntity.evaluation.versionReference}</dd>

                <dt><span id="etat">Etat</span></dt>
                <dd>{devisEntity.evaluation.etat}</dd>

                <dt><span id="duree">Durée</span></dt>
                <dd>{devisEntity.evaluation.duree} ms</dd>

                { devisEntity.evaluation.etatCode === evaluationEtat.ECHEC && (
                  <>
                    <dt><span id="message">Erreur</span></dt>
                    <dd>{devisEntity.evaluation.errorMessage}</dd>
                  </>
                )}
              </dl>


              <dl className="jh-entity-details">
                {devisEntity.evaluation.variables && devisEntity.evaluation.variables.length > 0 && (
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th>Résultat</th>
                      </tr>
                    </thead>
                    <tbody>
                      {devisEntity.evaluation.variables.map((variable, i) => (
                        <tr key={`entity-${i}`}>
                          <td>{variable.code}</td>
                          <td>{variable.errorMessage ? variable.errorMessage : variable.resultat}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
                {!devisEntity.evaluation.variables || (devisEntity.evaluation.variables && devisEntity.evaluation.variables.length === 0) && (<dt>Aucune variable évaluée</dt>)}
              </dl>

              <dl className="jh-entity-details">
                {devisEntity.evaluation.formules && devisEntity.evaluation.formules.length > 0 && (
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Nature</th>
                        <th>Résultat</th>
                      </tr>
                    </thead>
                    <tbody>
                      {devisEntity.evaluation.formules.map((formule, i) => (
                        <tr key={`entity-${i}`}>
                          <td>{formule.natureCode}</td>
                          <td>{formule.errorMessage ? formule.errorMessage : formule.resultat}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
                {!devisEntity.evaluation.formules || (devisEntity.evaluation.formules && devisEntity.evaluation.formules.length === 0) && (<dt>Aucune formule évaluée</dt>)}
              </dl>
            </fieldset>
          )}

        </Col>
      </Row>
  );
};

const mapStateToProps = ({ devis, document }: IRootState) => ({
  devisEntity: devis.entity,
  documentEntities: document.entities.byDevis[devis.entity.id],
  loadingDevis: devis.loading,
  loadingDocument: document.loading
});

const mapDispatchToProps = { getEntity, getDocumentsDevis };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DevisDetail);
