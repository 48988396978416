import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export interface IConfirmModalProps {
  showModal: boolean;
  title: string;
  message: object;
  handleValid: Function;
  handleClose: Function;
}

class ConfirmModal extends React.Component<IConfirmModalProps> {
  constructor(props) {
    super(props);
  }

  render() {
    const { handleClose, handleValid, showModal, title, message} = this.props;

    return (
      <Modal isOpen={showModal} toggle={handleClose} backdrop="static" id="document-delete-page" autoFocus={false}>
         <ModalHeader toggle={handleClose}>{title}</ModalHeader>
      <ModalBody id="tarifApp.produit.delete.question">{message}</ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <FontAwesomeIcon icon="ban" />
          &nbsp; Annuler
        </Button>
        <Button id="jhi-confirm-delete-produit" color="danger" onClick={handleValid}>
          <FontAwesomeIcon icon="trash" />
          &nbsp; Supprimer
        </Button>
      </ModalFooter>

      </Modal>
    );
  }
}

export default ConfirmModal;
