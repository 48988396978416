import axios from 'axios';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { ICode, defaultValue } from 'app/shared/model/code.model';

export const ACTION_TYPES = {
  FETCH_CODE: 'code/FETCH_CODE',
  RESET: 'code/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: {},
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

export type CodeState = Readonly<typeof initialState>;

// Reducer

export default (state: CodeState = initialState, action): CodeState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CODE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_CODE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_CODE):
      return {
        ...state,
        loading: false,
        entities: {
          ...state.entities,
          [action.meta.codeType]: [...action.payload.data],
        },
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/codes';

// Actions

export const getCodes = codeType => ({
  type: ACTION_TYPES.FETCH_CODE,
  payload: axios.post<ICode>(apiUrl, codeType),
  meta: codeType,
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
