import { IEvaluationComponent } from './evaluationComponent.model';

export interface IEvaluation {
  id?: number;
  dateExecution?: Date;
  duree?: number;
  numero?: string;
  etat?: string;
  etatCode?: string;
  initiateur?: string;
  errorMessage?: string;
  versionReference?: string;
  produit?: string;
  versionId?: number;
  variables?: IEvaluationComponent[];
  formules?: IEvaluationComponent[];
}

export const defaultValue: Readonly<IEvaluation> = {};
