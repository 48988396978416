import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IDocument, defaultValue } from 'app/shared/model/document.model';
import { IDocumentCreateAction, IVersionDeleteAction } from 'app/shared/util/custom-action.type';
import { cleanEntity } from 'app/shared/util/entity-utils';

export const ACTION_TYPES = {
  FETCH_DOCUMENT_BY_VERSION_LIST: 'document/FETCH_DOCUMENT_BY_VERSION_LIST',
  FETCH_DOCUMENT_BY_DEVIS_LIST: 'document/FETCH_DOCUMENT_BY_DEVIS_LIST',
  FETCH_DOCUMENT: 'document/FETCH_DOCUMENT',
  FETCH_GENERATION_DEVIS: 'document/GENERER_DEVIS',
  CREATE_DOCUMENT: 'document/CREATE_DOCUMENT',
  DELETE_DOCUMENT: 'document/DELETE_DOCUMENT',
  RESET: 'document/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  temporaire: {
    document: null,
    loading: false,
  },
  entities: {
    byDevis: {},
    byVersion: {},
  },
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

export type DocumentState = Readonly<typeof initialState>;

// Reducer

export default (state: DocumentState = initialState, action): DocumentState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_BY_VERSION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_BY_DEVIS_LIST):
    case REQUEST(ACTION_TYPES.FETCH_DOCUMENT):
    case REQUEST(ACTION_TYPES.CREATE_DOCUMENT):
    case REQUEST(ACTION_TYPES.DELETE_DOCUMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.FETCH_GENERATION_DEVIS):
      return {
        ...state,
        temporaire: {
          document: null,
          loading: true,
        },
      };
    case FAILURE(ACTION_TYPES.FETCH_DOCUMENT_BY_VERSION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_DOCUMENT_BY_DEVIS_LIST):
    case FAILURE(ACTION_TYPES.FETCH_DOCUMENT):
    case FAILURE(ACTION_TYPES.CREATE_DOCUMENT):
    case FAILURE(ACTION_TYPES.DELETE_DOCUMENT):
    case FAILURE(ACTION_TYPES.FETCH_GENERATION_DEVIS):
      return {
        ...state,
        loading: false,
        updating: false,
        temporaire: {
          document: null,
          loading: false,
        },
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_BY_VERSION_LIST):
      return {
        ...state,
        loading: false,
        entities: {
          ...state.entities,
          byVersion: {
            ...state.entities.byVersion,
            [action.meta]: [...action.payload.data],
          },
        },
      };
    case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_BY_DEVIS_LIST):
      return {
        ...state,
        loading: false,
        entities: {
          ...state.entities,
          byDevis: {
            ...state.entities.byDevis,
            [action.meta]: [...action.payload.data],
          },
        },
      };
    case SUCCESS(ACTION_TYPES.FETCH_GENERATION_DEVIS):
      return {
        ...state,
        temporaire: {
          document: action.payload.data,
          loading: false,
        },
      };
    case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_DOCUMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_DOCUMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/documents';

// Actions
export const getEntitiesByVersion = versionId => {
  const requestUrl = `${apiUrl}/byVersion/${versionId}`;
  return {
    type: ACTION_TYPES.FETCH_DOCUMENT_BY_VERSION_LIST,
    payload: axios.get<IDocument>(requestUrl),
    meta: versionId,
  };
};

export const getEntitiesByDevis = devisId => {
  const requestUrl = `${apiUrl}/byDevis/${devisId}`;
  return {
    type: ACTION_TYPES.FETCH_DOCUMENT_BY_DEVIS_LIST,
    payload: axios.get<IDocument>(requestUrl),
    meta: devisId,
  };
};

export const getEntity: ICrudGetAction<IDocument> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_DOCUMENT,
    payload: axios.get<IDocument>(requestUrl),
  };
};

export const createEntity: IDocumentCreateAction<IDocument> = (entity, file) => async dispatch => {
  const categorieMultipartFormParam = 'document';
  const fileMultipartFormParam = 'file';
  const formData: FormData = new FormData();
  const documentAsJsonBlob: Blob = new Blob([JSON.stringify(entity)], { type: 'application/json' });

  formData.append(categorieMultipartFormParam, documentAsJsonBlob);
  formData.append(fileMultipartFormParam, file);

  const result = await dispatch({
    type: ACTION_TYPES.CREATE_DOCUMENT,
    payload: axios.post(apiUrl, formData),
  });

  dispatch(getEntitiesByVersion(entity.objetRefId));

  return result;
};

export const genererDevis: IDocumentCreateAction<IDocument> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_GENERATION_DEVIS,
    payload: axios.post(apiUrl + '/generer/devis', cleanEntity(entity)),
  });

  return result;
};

export const deleteEntity: IVersionDeleteAction<IDocument> = (id, idVersion) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_DOCUMENT,
    payload: axios.delete(requestUrl),
  });

  dispatch(getEntitiesByVersion(idVersion));
  return result;
};

export const reset: VoidFunction = () => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.RESET,
  });
};
