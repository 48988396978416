export const codeTypes = {
  DOCUMENT_CATEGORIE: 'DOCUMENT_CATEGORIE',
  FORMULE_TYPE: 'FORMULE_TYPE',
  VERSION_ETAT: 'VERSION_ETAT',
  VARIABLE_NATURE: 'VARIABLE_NATURE',
  VARIABLE_TYPE: 'VARIABLE_TYPE',
};

export const variableType = {
  BOOLEAN: 'BOOLEAN:VARIABLE_TYPE',
  STRING: 'STRING:VARIABLE_TYPE',
  DATE: 'DATE:VARIABLE_TYPE',
  NUMBER: 'NUMBER:VARIABLE_TYPE',
};

export const variableNature = {
  ENTREE: 'ENTREE:VARIABLE_NATURE',
  CALCULEE: 'CALCULEE:VARIABLE_NATURE',
  CALCULEE_MATRICE: 'CALCULEE_MATRICE:VARIABLE_NATURE',
  CONSTANTE_MATRICE: 'CONSTANTE_MATRICE:VARIABLE_NATURE',
  CONSTANTE: 'CONSTANTE:VARIABLE_NATURE',
};

export const isVariableMatricielle = nature => {
  return nature === 'CALCULEE_MATRICE:VARIABLE_NATURE' || nature === 'CONSTANTE_MATRICE:VARIABLE_NATURE';
};

export const evaluationEtat = {
  INITIEE: 'INITIEE:EVALUATION_ETAT',
  ECHEC: 'ECHEC:EVALUATION_ETAT',
  SUCCESS: 'SUCCESS:EVALUATION_ETAT',
};

export const evaluationComponentType = {
  VARIABLE: 'VARIABLE:EVALUATION_COMPONENT_TYPE',
  FORMULE: 'FORMULE:EVALUATION_COMPONENT_TYPE',
};

export const versionEtat = {
  PUBLIE: 'PUBLIE:VERSION_ETAT',
  BROUILLON: 'BROUILLON:VERSION_ETAT',
  ARCHIVE: 'ARCHIVE:VERSION_ETAT',
};
