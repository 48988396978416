import { IVersion } from "./version.model";


export interface IProduit {
  id?: number;
  nom?: string;
  code?: string;
  description?: string;
  versions?: IVersion[];
}

export const defaultValue: Readonly<IProduit> = {};
