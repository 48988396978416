import { IEntity } from './entity.model';

export interface IFormule extends IEntity {
  nom?: string;
  description?: string;
  type?: string;
  formule?: string;
  orderIndex?: number;
}

export const defaultValue: Readonly<IFormule> = {};
