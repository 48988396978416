import './home.scss';

import React from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
import Footer from 'app/shared/layout/footer/footer';

export type IHomeProp = StateProps;

export const Home = (props: IHomeProp) => {
  const { account } = props;

  return (
<>
    <Row>
      <Col md="10">
        <h2>Bienvenue sur le tarificateur LYA</h2>
        <p className="lead">Tarification TNS by Lya</p>
        {account && account.login ? (
          <div>
            <Alert color="success">Vous êtes actuellement connecté avec le login : {account.login}.</Alert>
          </div>
        ) : (
          <div>
            <Alert color="warning">
              Vous pouvez
              <Link to="/login" className="alert-link">
                {' '}
                vous connecter
              </Link>
              , et ainsi accéder aux produits et prospect qui ont demandé un devis.
             </Alert>
          </div>
        )}
        {account && account.login && (
          <>
            <p>Consulter <Link to="/catalogue/produit" className="alert-link">
                {' '}
                les produits déjà paramétrés
              </Link>
               ,ou
               <Link to="/catalogue/evaluation" className="alert-link">
                {' '}
                visualiser les évaluations réalisées
              </Link>
              </p>
          </>
        )}
      </Col>
      <Col md="4" className="pad">
        <span className="hipster rounded" />
      </Col>
    </Row>

    <Footer />
    </>

  );
};

const mapStateToProps = storeState => ({
  account: storeState.authentication.account,
  isAuthenticated: storeState.authentication.isAuthenticated,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Home);
