import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Evaluation from './evaluation';
import EvaluationDetail from './evaluation-detail';
import EvaluationDeleteDialog from './evaluation-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={EvaluationDetail} />
      <ErrorBoundaryRoute path={match.url} component={Evaluation} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={EvaluationDeleteDialog} />
  </>
);

export default Routes;
